<template>
  <div id="app">
    <nav :class="{'hideNav': hideNav}">
      <svg class="leaguesLogo">
        <use xlink:href="#logo_vivid"></use>
      </svg>
      <div class="hamburger" @click="showNav = !showNav">
        <span></span>
      </div>
      <ul class="navigation" :class="{'navigation__show': showNav}">
        <li class="navigation__item navigation__active"><a href="">App</a></li>
        <li class="navigation__item"><a href="https://www.leagues.football/">Livestream</a></li>
        <li class="navigation__item"><a href="https://clubs.leagues.football/category/aktuell/">Aktuell</a></li>
        <li class="navigation__item"><a href="https://clubs.leagues.football/team/">Team</a></li>
        <li class="navigation__item"><a href="https://clubs.leagues.football/jobs/">Jobs</a></li>
      </ul>
    </nav>
    <div class="stickyNav">
      <svg class="leaguesLogo">
        <use xlink:href="#logo_vivid"></use>
      </svg>
    </div>
    <div>
        <div class="leagues" ref="leagues" @scroll="handleScroll">
          <section class="section-fixed">
            <row-opener :userInteraction="userInteraction"/>
          </section>
          <section ref="kickoff" id="kickoff">
            <row-kickoff :userInteraction="userInteraction"/>
          </section>
          <section>
            <row-products :userInteraction="userInteraction"/>
          </section>
          <section>
            <row-ownplattform :userInteraction="userInteraction"/>
          </section>
          <section>
            <row-contact :userInteraction="userInteraction"/>
          </section>
          <footer>
            <a href="https://www.leagues.football/imprint" target="_blank">Impressum</a>
             |
            <a href="https://www.leagues.football/datenschutz" target="_blank">Datenschutz</a>
          </footer>
        </div>
    </div>
    <the-s-v-g-sprite />
  </div>
</template>

<script>
import {EventBus} from "@/main";
import './assets/app-fonts.css';
import RowKickoff from "@/components/rows/row-kickoff";
import RowOpener from "@/components/rows/row-opener";
import RowProducts from "@/components/rows/row-products";
import RowOwnplattform from "@/components/rows/row-ownplattform";
import RowContact from "@/components/rows/row-contact";
import TheSVGSprite from "@/components/svgSprite";
export default {
  name: 'App',
  components: {
    TheSVGSprite,
    RowContact,
    RowOwnplattform,
    RowProducts,
    RowOpener,
    RowKickoff
  },
  data() {
    return {
      showNav: false,
      userInteraction: false,
      scrollPos: 0,
      hideNav: false,
      setNavViaComponent: false
    }
  },
  methods: {
    handleScroll(e){
      this.scrollPos = e.srcElement.scrollTop;
      if(this.scrollPos > window.innerHeight / 3 && !this.setNavViaComponent){
        this.hideNav = true
      }else{
        this.hideNav = false
      }
    },
    now(){
      this.$store.commit('interacted', true);
    }
  },
  created() {
    EventBus.$on('userHasInteracted', () => {
      this.userInteraction = true;
    })
    EventBus.$on('showNav', (e) => {
      console.log(e);
      if(e){
        this.hideNav = false;
        this.setNavViaComponent = true;
      }else{
        this.hideNav = true
        this.setNavViaComponent = false;
      }
    })
    EventBus.$on('finishOpener', () => {
      this.$nextTick(() => {
        if(window.innerWidth < 600){
          return false;
        }
        if(this.scrollPos < 400){
          const element = this.$refs.leagues;
          element.scrollTo({top: window.innerHeight / 2, behavior: 'smooth'});
        }
      });
    })
  }
}
</script>

<style lang="scss">
html {
  overscroll-behavior-y: none;
}
html, body{
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Gilroy-Medium";
  background: #000;
}
$distance: 80vh;
#app {
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  *, *:after, *:before{
    box-sizing: border-box;
  }
}
.leagues{
  font-family: "Gilroy-Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  perspective: 1px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #000;
  height: 100vh;
  @media (min-device-width: 600px) {
   //height: calc(100vh - 60px);
    padding-top: 60px;
  }
}
section{
  @media (max-device-width: 600px) {
    min-height: 80vh;
  }
//  transform: translateZ(-1px) scale(2);
  position: relative;
  margin: 0;
  padding: 0;
  width: 100vw;
}
section.section-fixed{
  display: none;
  @media (min-device-width: 600px) {
    display: block;
    transform: translateZ(-1px) scale(2) translateY(-40px);
  }
  position: relative;
  overflow: hidden;
  z-index: -1;
  min-height: unset;
  video{
    width: 100%;
    background: #000;
    position: relative;
    left: 0;
    top: 0;
  }
}
.parallaxNormalize{

}
.maxWidth{
  width: 100vw;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  .section-fixed & {
    padding: 0;
  }
  @media (min-device-width: 1400px) {
    .section-fixed & {
      padding: 0 10px;
    }
  }
}
.content{
  padding: 20px;
  @media (min-device-width: 800px) {
    padding: 50px;
  }
  @media (min-device-width: 1020px) {
    padding: 50px 100px;
  }
  //height: 80vh;
}
.headline--large{
  color: #0ff;
  font-weight: bold;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 1.5;
}
.headline--verylarge{
  color: #FFF;
  margin: 10px auto;
  width: calc(100% - 20px);
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  @media (min-device-width: 600px) {
    font-size: 4rem;
    width: unset;
    max-width: 80%;
    margin: 30px auto 0 auto;
  }
  text-shadow: 4px 5px 5px rgba(0,0,0,0.8);
}
.headline--semi{
  color: #FFF;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 1.2;
}
.text{
  color: #FFF;
  line-height: 1.4;
  margin-top: 10px;
}
nav{
  position: fixed;
  width: 100vw;
  max-width: 1400px;
  font-weight: bold;
  @media (min-device-width: 600px) {
    height: 100px;
    background: #000;
  }
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  top: 0;
  transition: opacity ease-in-out 0.5s;
  @media (min-device-width: 600px) {
    &.hideNav {
      opacity: 0;
      pointer-events: none;
    }
  }
}
.hamburger{
  display: none;
}
@media (max-device-width: 600px) {
  .hamburger {
    z-index: 1;
    background: #000000;
    position: fixed;
    width: 45px;
    height: 45px;
    right: 0;
    top: 40px;
    display: block;
    pointer-events: all;
    span {
      height: 3px;
      background: #FFF;
      width: 80%;
      margin: 20px 10%;
      display: block;
      position: relative;

      &:before,
      &:after {
        content: '';
        width: 100%;
        background: #ffffff;
        height: 3px;
        display: block;
        position: absolute;
      }

      &:before {
        top: 10px;
      }

      &:after {
        bottom: 10px;
      }
    }
  }
}
.navigation{
  &.navigation__show{
    @media (max-device-width: 600px) {
      left: 0;
      top: 0;
      padding: 40px;
      background: #000;
      position: absolute;
      width: 100vw;
      height: 100vh;
    }
  }
  @media (max-device-width: 600px) {
    position: absolute;
    left: -100vw;
    top: -100vh;
  }

  width: calc(100% - 180px);
  list-style: none;
  text-align: right;
  display: inline-block;
  *{
    font-family: "Gilroy-Medium";
  }
}
.stickyNav{
  display: none;
  @media (min-device-width: 600px) {
    display: block;
    position: fixed;
    z-index: 8;
    width: 100vw;
    max-width: 1400px;
    height: 100px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }
}
.navigation__item{
  @media (max-device-width: 600px) {
    display: block;
    color: #FFF;
    font-size: 3rem;
    text-align: left;
    width: 100vw;
    line-height: 1.5;
    font-weight: bold;
    font-family: "Gilroy-Bold";
  }

  &.navigation__active{
    a{
      color: #0afeff;
    }
  }

  display: inline-block;
  &:last-child{
    a{
      margin: 0 0 0 10px;
    }
  }
  a{
    color: #FFF;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 10px;
    font-family: "Gilroy-Bold";
  }
}
.leaguesLogo{
  display: inline-block;
  width: 150px;
  height: 45px;
  margin: 40px 0;
  @media (min-device-width: 600px) {
    height: 80px;
    margin: 10px 0;
  }
  vertical-align: middle;
  background: #000;
  padding: 0 10px;
}
footer{
  margin: 20px !important;
  background: #000;
  color: #FFF;
  display: block;
  text-align: center;
  a{
    color: #FFF;
  }
}
.leagues_marker{
  font-weight: bold;
  color: #0afeff;
}
</style>
