import Vue from 'vue'
import App from './App.vue'
import 'reset-css';

Vue.config.productionTip = false;

import Vuex from 'vuex'
Vue.use(Vuex);
import { store } from './store/store'
export const EventBus = new Vue();

import axios from 'axios'
Vue.prototype.$http = axios

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
