<template>
  <div class="row">
    <div class="column">
      <slot name="left"></slot>
    </div>
    <div class="column">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "column"
}
</script>

<style lang="scss" scoped>
  .row{
    @media (min-device-width: 600px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-template-areas:
    "left right";
    }
    @media (max-device-width: 600px) {
      //height: 200vh;
    }
  }
  .column{
    @media (min-device-width: 600px) {
      grid-area: left;
      text-align: center;
    }
    @media (max-device-width: 600px) {
      //height: 100vh;
      //position: sticky;
      top: 0;
      //overflow: hidden;
    }
  }
  .column:nth-child(2){
    @media (min-device-width: 600px) {
      grid-area: right;
    }
    @media (max-device-width: 600px) {
      //height: 100vh;
      //position: relative;
      //z-index: 1;
    }
  }
  .column__layout{
    height: 100%;
    padding: 100px  20px 20px 20px!important;
    @media (min-device-width: 600px) {
      padding: 20px!important;
    }
  }
</style>
