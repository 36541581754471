<template>
  <div class="thisRow">
    <div class="maxWidth">
      <column>
        <template v-slot:left>
          <div class="column__layout" style="background: #283234">
            <h2>Match Reporter</h2>
            <div class="column__floattext">
              Anpfiff, Halbzeit, Tor. Und alles andere, was auf dem Rasen passiert. Mit dem intuitiven Match Reporter können alle Spieldaten erfasst und direkt an die Fans ausgespielt werden. Denn die intelligente <span class="leagues_marker">LEAGUES</span>-Cloud generiert daraus automatisch Content für die Club-eigene Timeline.
            </div>
            <div class="column__mobile">
              <mobilephone
                  video="https://cdn.leagues.network/landingpage/creator-match.mp4"
                  :userInteraction="userInteraction"
                  :sound="false"
                  waypointPosition="top"
              />
            </div>
          </div>
        </template>
        <template v-slot:right>
          <div class="column__layout" style="background: #333d40">
            <h2>Content Creation</h2>
            <div class="column__floattext">
              Im Kreativmodus aus dem Zentrum der Emotionen eindrucksvolle Erlebnisse festhalten. Ob Bild, Audio oder Video – alles landet sekundenschnell und unmittelbar auf den Screens der Fans und Supporter. Einfach die stärksten Content-Tools für Fußball-Clubs!
            </div>
            <div class="column__mobile">
              <mobilephone
                            video="https://cdn.leagues.network/landingpage/creator-creative.mp4"
                            :userInteraction="userInteraction"
                            :sound="false"
                            waypointPosition="top"
              />
            </div>
          </div>
        </template>
      </column>
    </div>
  </div>
</template>

<script>
import Column from "@/components/column";
import Mobilephone from "@/components/elements/mobilephone";
export default {
  name: "row-products",
  components: {Mobilephone, Column},
  props: {
    userInteraction: Boolean
  }
}
</script>

<style lang="scss" scoped>
  .thisRow{
    background: #0ff;
  }
  .column__layout{
    h2{
      font-weight: bold;
      color: #FFF;
      font-size: 2rem;
    }
    position: relative;
    color: #FFF !important;
    padding: 20px;
    font-size: 1.2rem;
    line-height: 1.5;
    @media (min-device-width: 600px) {
      padding: 60px 20px !important;
      min-height: calc(727px + 400px);
    }
    .column__floattext{
      padding: 0 0 10px 0;
      @media (min-device-width: 600px) {
        padding: 20px;
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }
      text-align: left;
    }
  }
  @media (min-device-width: 600px) {
    .column__mobile{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
    }
  }
</style>
