<template>
  <div class="maxWidth">
    <div class="thisWaypoint">
      <waypoint @change="onChange" :active="true"/>
    </div>
    <video webkit-playsinline playsInline muted loop
           preload="metadata"
           ref="leaguesPlayer"
           poster="https://cdn.leagues.network/landingpage/goal.mp4.jpg"
    >
      <source src="https://cdn.leagues.network/landingpage/goal.mp4" type="video/mp4">
    </video>
  </div>

</template>

<script>
import { Waypoint } from "vue-waypoint";
import {EventBus} from "@/main";
export default {
  name: "row-opener",
  components: {
    Waypoint
  },
  data() {
    return {
      video: {
        play: false
      },
      sendScroll: false
    }
  },
  props: {
    userInteraction: Boolean
  },
  methods: {
    onChange(waypointState){
      if(waypointState.going === 'IN'){
        this.playVideo();
      }else{
        this.videoPlayer.pause();
      }
    },
    playVideo() {
      if(window.innerWidth < 600){
        return false;
      }
      //EventBus.$emit('userHasInteracted');
      this.videoPlayer.currentTime = 0;
      const playPromise = this.videoPlayer.play();
      const self = this;
      if (playPromise !== undefined) {
        this.videoPlayer.onplay = function () {
          //Wenn das Video abgespielt wird, wird der Suspend Layer ausgeblendet!
          self.video.play = true;
        };
        this.videoPlayer.ontimeupdate = function () {
          const seenPercent = Math.round( (Math.floor(self.videoPlayer.currentTime) / Math.floor(self.videoPlayer.duration)) * 100).toFixed(0);
          if(seenPercent > 80 && !self.sendScroll){
            EventBus.$emit('finishOpener');
            self.sendScroll = true;
          }
        };
        this.videoPlayer.onended = function () {
          const intervalRewind = setInterval(function(){
            const currentTime = self.videoPlayer.currentTime - 1;
            if(currentTime > 0){
              self.videoPlayer.currentTime = currentTime;
            }else{
              clearInterval(intervalRewind);
              self.video.play = false;
            }
          }, 100)
        }
      }else{
        console.error('NOT PLAYED');
      }
    }
  },
  mounted() {
    this.playVideo();
  },
  computed: {
    videoPlayer(){
      return this.$refs.leaguesPlayer;
    }
  }
}
</script>

<style lang="scss" scoped>
video{
  pointer-events: none;
}
.thisWaypoint{
  display: block;
  height: 10px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 30%;
}
</style>
