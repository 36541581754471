<template>
  <div>
    <div class="mobilePhone">
      <Waypoint
          v-if="waypointPosition === 'top'"
          @change="onChange" :active="true"></Waypoint>
      <div class="mobilePhone__camera">
      </div>
      <div v-if="sound" @click="toggleSound" class="mobilePhone__mute">
        <sound :muted="muted"/>
      </div>
      <video
          webkit-playsinline playsInline loop muted
          onerror="errorVideo()"
          preload="metadata"
          :poster="video + '.jpg'"
          ref="mobileDevicePlayer">
        <source
            :src="video"
            type="video/mp4">
      </video>
    </div>
      <div
          v-if="waypointPosition === 'center'"
          class="waypoint_center"
      >
        <Waypoint
            @change="onChange" :active="true"></Waypoint>
      </div>
      <div
          v-if="waypointPosition === 'bottom'"
          class="waypoint_bottom"
      >
        <Waypoint
            @change="onChange" :active="true"></Waypoint>
      </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Waypoint } from "vue-waypoint";
import {EventBus} from "@/main";
import Sound from "@/components/elements/sound";
export default defineComponent ({
  name: "mobilephone",
  components: {
    Sound,
    Waypoint,
  },
  props: {
    video: String,
    sound: Boolean,
    userInteraction: Boolean,
    waypointPosition: String,
    order: Number
  },
  data() {
    return {
      interacted: false,
      waypoint: 'OUT',
      muted: true
    }
  },
  watch: {
    userInteraction: function() { // watch it
      if(this.userInteraction && this.waypoint === 'IN'){
        this.startWithSound();
      }else{
        this.videoPlayer.pause();
      }
    }
  },
  methods: {
    toggleSound(){
      EventBus.$emit('userHasInteracted');
      this.videoPlayer.muted = !this.videoPlayer.muted;
      this.muted = !this.muted;
      EventBus.$emit('finishOpener');
    },
    startWithSound(){
      EventBus.$emit('userHasInteracted');
      if(this.sound){
        this.muted = false;
        this.videoPlayer.muted = false;
      }
    },
    errorVideo(){
      this.videoPlayer.muted = true;
      this.muted = true;
      this.videoPlayer.play();
    },
    startVideo(){
      const playPromise = this.videoPlayer.play();
      if(this.videoPlayer.muted && this.sound){
        this.videoPlayer.muted = false;
        this.muted = false;
      }
      const self = this;
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Autoplay started!
          if(self.videoPlayer.paused){
            //Wurde aber wieder angehalten
            self.videoPlayer.muted = true;
            self.muted = true;
            self.videoPlayer.play();
          }
        }).catch(() => {
          self.videoPlayer.muted = true;
          self.muted = true;
          self.videoPlayer.play();
        });
      }
    },
    onChange(waypointState){
      this.waypoint = waypointState.going;
      if(this.waypoint === 'IN'){
        this.startVideo();
      }else{
        this.videoPlayer.pause();
      }
    }
  },
  computed: {
    videoPlayer(){
      return this.$refs.mobileDevicePlayer;
    }
  }
})
</script>

<style lang="scss" scoped>
  video{
    pointer-events: none;
    width: 100%;
  }
  .mobilePhone{
    $deviceHeight: 80vh;
    background: #000;
    @media (min-device-width: 600px) {
      video {
        width: 348px;
        height: 727px;
      }
    }
    @media (max-device-width: 600px) {
      width: calc(100vw - 50px);
      min-width: 200px;
    }
    border: 4px solid #676767;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    box-shadow: 0 0.5em 2em 0.2em rgba(0, 0, 0, 0.33), 0 0 0 0.5px #000 inset;
  }
  .mobilePhone__camera{
    display: none;
    border-radius: 100%;
    position: absolute;
    z-index: 9;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    background-color: #101010;
    box-shadow: inset 0px -3px 2px 0px rgba(255,255,255,0.2);
    &:after{
      content: "";
      position: absolute;
      background-color: #2d4d76;
      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
      top: 3px;
      left: 3px;
      display: block;
      border-radius: 4px;
      box-shadow: inset 0px -2px 2px rgba(0,0,0,0.5);
    }
  }
  .mobilePhone__mute{
    position: absolute;
    pointer-events: all;
    cursor: pointer;
    font-weight: bold;
    background: #46feff;
    padding: 5px;
    right: 5px;
    top: 63px;
    color: #000000;
    &:after{
      position: absolute;
      top: -100px;
      height: 100vh;
      width: 100vw;
      content: '';
      display: block;
      right: -100px;
    }
  }
  .waypoint_bottom{
    @media (max-device-width: 600px) {
      position: absolute;
      width: 100vw;
      height: 100px;
      z-index: 9;
      bottom: 10%;
    }
  }
  .waypoint_center{
      position: absolute;
      width: 100vw;
      height: 100px;
      z-index: 9;
      bottom: 50%;
  }
</style>
