import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        interacted: false
    },
    mutations: {
        interacted(state, value) {
            state.interacted = value
        },
    },
    getters: {
        interacted: state => state.interacted,
    }
})
