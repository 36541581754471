<template>
  <div :class="{'isNotMuted': muted}">
    <div v-if="!muted">
      TON AUS
    </div>
    <div v-else>
      TON EINSCHALTEN
    </div>
    <!--svg
        v-if="!muted"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 35 35"
        xml:space="preserve"
    >
     <g id="Ebene_2"><path class="st1" d="M1.9 12h5.3v10.8H1.9zM18.2 3.3v28.5l-11-8.9V12zM26.1 6.3s7.1 3.5 7 11.2c-.1 7.8-7 11.1-7 11.1"/><path class="st1" d="M24.5 10.7s4.2 2.1 4.2 6.7-4.2 6.6-4.2 6.6M21.2 13.8s2.6 1.3 2.6 4.2-2.6 4.1-2.6 4.1"/></g>
    </svg>
    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" xml:space="preserve"><g id="Ebene_2"><path class="st1" d="M1.9 12h5.3v10.8H1.9zM18.2 3.3v28.5l-11-8.9V12z"/></g>
      </svg
      -->
  </div>
</template>

<script>
export default {
  name: "sound",
  props: {
    muted: Boolean
  }
}
</script>

<style scoped>
.st1{fill:none;stroke:#fff;stroke-miterlimit:10}
.st0{display:none}.st1,.st2{fill:none;stroke:#fff;stroke-miterlimit:10}.st2{display:inline}
</style>
